import { fetchMovies } from "../features/moviesSlice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./movies.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import Details from "../details/Details";


const Movies = () => {
  const link1 = useParams().theatrelink;
  const link2 = useParams().theatrelink2;
  const selected = `${link1}/${link2}`;
  const { movies, error, isLoading } = useSelector(
    (state) => state.allMoviesStore
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMovies(selected));
  }, [dispatch, selected]);

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (movies.length === 0) {
    return (
      <div className={styles.nomovies}>
        <h3>No movie listings available for this location</h3>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Now Playing</h1>
      {movies.map((res, index) => res.moviename !== "" && (
        <Link to={`/details${res.link}`} key={index}>
          <div className={styles.card}>
            <img src={res.poster} alt="Movie poster" />
            <div>
              <h2>{res.moviename}</h2>
              <h4>{res.showtimes}</h4>
              <span className={styles.summary}>SUMMARY</span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

<div>
  <Details />
</div>

export default Movies;
