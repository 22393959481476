import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myLocation } from "../features/locationSlice";
import { Link } from "react-router-dom";
import styles from "./location.module.css";
import Spinner from "../spinner/Spinner";

const Location = () => {

	const { currentLocation, isLoading, error } = useSelector((state) => state.allLocation);

	const [loc, setLoc] = useState("");

	const dispatch = useDispatch();

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const updatedLocation = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				};
				setLoc(updatedLocation);
				dispatch(myLocation(updatedLocation));
			},
			(error) => {
				const errorLocation = {
					latitude: 'err-latitude',
					longitude: 'err-longitude'
				};
				dispatch(myLocation(errorLocation));
			}
		);
	}, [dispatch]);

	if (loc.length === 0) {
		return <Spinner />
	}

	if (isLoading) {
		return <Spinner />
	}

	if (error) {
		return <div className={styles.error}>{error}</div>;
	}


	return (
		<div className={styles.container}>
			{
				currentLocation && currentLocation.map((res, index) => (
					<Link to={`/movies/${res.link}`} key={index}>
						<div className={styles.card}>
							<div >
								<h2>{res.name}</h2>
								<h4>{res.address}</h4>
							</div>
						</div>
					</Link>
				))
			}
		</div>
	);
}


export default Location;
