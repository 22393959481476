import React from 'react';
import styles from './header.module.css';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import { NavLink } from 'react-router-dom';

const Header = () => {
	return (
		<div className={styles.header}>
			<h1>MOVIE SHOWTIMES</h1>
			<div className={styles.iconContainer}>
				<NavLink to={"/"} className={styles.icons}>
					<HomeOutlinedIcon />
				</NavLink>

				<NavLink to={"/location"} className={styles.icons}>
					<AddLocationAltOutlinedIcon />
				</NavLink>

				<NavLink to={"/faves"} className={styles.icons}>
					<FavoriteBorderOutlinedIcon />
				</NavLink>
			</div>
		</div>
	);
}

export default Header
