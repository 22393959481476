import React from 'react'
import styles from './footer.module.css'

const Footer = () => {
	return (
		<div className={styles.footer}>
			<img src="/dm-logo.png" alt="Logo" />
			<h2>Adi Gonsalves</h2>
			<h4>416 298 6024</h4>
		</div>
	)
}



export default Footer
