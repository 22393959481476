import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Axios from "axios";

const sliceName = "allTheatres";

export const fetchTheatres = createAsyncThunk(
  `${sliceName}/fetchTheatres`,
  async (provcity) => {
    const { data } = await Axios.get(`/theatresapi/${provcity}`);
    return data;
  }
);

export const theatresSlice = createSlice({
  name: sliceName,
  initialState: {
    theatres: [],
    error: "",
    selectedTheatre: "",
  },
  reducers: {
    setSelectedTheatre: (state, action) =>
      (state.selectedTheatre = action.payload),
  },
  extraReducers: {
    [fetchTheatres.pending]: (state, action) => {
      state.error = null;
      state.isLoading = true;
    },
    [fetchTheatres.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.theatres = action.payload;
    },
    [fetchTheatres.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = "Sorry no data available, please try later";
    },
  },
});
export const { setSelectedTheatre } = theatresSlice.actions;
export default theatresSlice.reducer;
