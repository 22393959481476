import {  createSlice } from "@reduxjs/toolkit";

export const favsSlice = createSlice({
	name: "favs",
	initialState: {
		favTheatres: [],
		selectedTheatre: {},
		isLoading: false,
		error: "",
	},
	reducers: {
		setFavTheatres: (state, action) => {
			
			if (state.favTheatres.length === 0 || !state.favTheatres.find((theatre) => theatre.name === action.payload.name)) {
				state.favTheatres.push(action.payload);
	  		state.selectedTheatre = action.payload;
	  		state.isLoading = false;

		};
		},
		removeFavTheatre: (state, action) => {
			state.isLoading = true;
			state.favTheatres = state.favTheatres.filter(
				(theatre) => theatre.name !== action.payload
				);
			state.isLoading = false;
		}
	},
	
});

export const { setFavTheatres, removeFavTheatre } = favsSlice.actions;
export default favsSlice.reducer;


