import React from "react";
import { fetchDetails } from "../features/detailsSlice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import styles from "./details.module.css";
import { Fade } from "react-reveal";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../spinner/Spinner";


const Details = () => {
  const selectedMovie1 = useParams().details2;
  const selectedMovie2 = useParams().details3;
  const selected = `${selectedMovie1}/${selectedMovie2}`;

  const [openModal, setOpenModal] = useState(true);
  const toggleOpen = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDetails(selected));
  }, [dispatch, selected]);
  const { details, error, isLoading } = useSelector((state) => state.allDetails);
  const history = useHistory();

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <>
      {openModal && (
        <div>
          <div className={styles.overlay} onClick={() => {
            toggleOpen();
            history.goBack();
          }}>
          </div>
          <Fade bottom>
            <div className={styles.modalContent}>
              <h1>Summary</h1>
              {details.map((res, index) => (
                <div key={index}>
                  <img src={res.posterBig} className={styles.bigPoster} alt="Movie Poster" key={res.posterBig} />
                  <p className={styles.description}>{res.descrip}</p>
                </div>
              ))}
              <button onClick={() => { toggleOpen(); history.goBack(); }} className={styles.button}>CLOSE</button>
            </div>
          </Fade>
        </div>
      )}
    </>
  );
};

export default Details;
