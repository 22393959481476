import React from "react";
import { fetchTheatres } from "../features/theatresSlice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./theatres.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import { useState } from "react";
import { setFavTheatres } from "../features/favsSlice";
import FavoriteIcon from '@material-ui/icons/Favorite';

const Theatres = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const city = useParams().city;
  const cityname = useParams().cityname;
  const province = useParams().province;
  const provcity = `${province}/${city}`;

  useEffect(() => {
    dispatch(fetchTheatres(provcity));

  }, [dispatch, provcity]);

  const addFavouriteTheatre = (theatre) => {
    dispatch(setFavTheatres(theatre));
  };


  const { theatres, error, isLoading } = useSelector(
    (state) => state.allTheatres
  );

  const filteredTheatres = theatres.filter((res) => res.theatre.toLowerCase().includes(searchTerm.toLowerCase()));


  if (error) {
    return <div className={styles.error}>{error}</div>;
  }



  if (isLoading) {
    return <Spinner />
  }
  return (
    <div className={styles.container}>
      <h1>{cityname} Movie Theatres</h1>
      <input type="text" placeholder="Type theatre name to search" className={styles.search} onChange={e => setSearchTerm(e.target.value)} />
      <div>
        {filteredTheatres.map((res, index) => (
          <div key={index}>
            <div className={styles.card} >
              <Link to={`/movies/${res.link}`} >
                <div>
                  <h2>{res.theatre}</h2>
                  <h3>{res.address}</h3>
                </div>
              </Link>

              <div className={styles.favButton} onClick={() => addFavouriteTheatre({ name: res.theatre, link: res.link })}>
                <div>Add to favourites</div>
                <FavoriteIcon fontSize="small" className={styles.FavoriteIcon} /></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Theatres;
