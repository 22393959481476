import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchCities } from '../features/citiesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Spinner from '../spinner/Spinner';
import styles from './cities.module.css';



const Cities = () => {

	const dispatch = useDispatch();

	const province = useParams().province;

	const { cities, isLoading, error } = useSelector((state) => state.allCities);



	useEffect(() => {
		dispatch(fetchCities(province));
	}, [dispatch, province]);

	if (error) {
		return <div className={styles.error}>{error}</div>;
	}

	if (isLoading) {
		return <Spinner />
	}

	return (
		<div className={styles.container}>
			<div className={styles.heading}>
				<h1>Cities</h1>
			</div>
			{
				cities.map((res, index) => (
					<div key={index}>
						<Link to={`/theatres${res.links}${province}/${res.city}`}>
							<h2>{res.city}</h2>
						</Link>
					</div>

				))
			}
		</div>
	);

};

export default Cities;
