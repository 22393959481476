import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
const sliceName = "allMovies";
export const fetchMovies = createAsyncThunk(
  `${sliceName}/fetchMovies`,
  async (selected) => {
    const { data } = await Axios.get(`/moviesapi/${selected}`);
    return data;
  }
);
export const allMoviesSlice = createSlice({
  name: sliceName,
  initialState: {
    movies: [],
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchMovies.pending]: (state, action) => {
      state.error = null;
      state.isLoading = true;
    },
    [fetchMovies.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.movies = action.payload;
    },
    [fetchMovies.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = "Sorry no data available";
    },
  },
});
export const { actions } = allMoviesSlice;
export default allMoviesSlice.reducer;
