import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const sliceName = "theatreByLocation";
export const myLocation = createAsyncThunk(
	`${sliceName}/myLocation`,
	async (updatedLocation) => {
		const lat = updatedLocation.latitude;
		const lon = updatedLocation.longitude;
		const { data } = await Axios.get(`/locationapi/${lat}/${lon}`);
		return data;
	}
);

const locationSlice = createSlice({
	name: sliceName,
	initialState: {
		currentLocation: [],
	},
	reducers: {},
	extraReducers: {
		[myLocation.pending]: (state, action) => {
			state.error = null;
			state.isLoading = true;
		},
		[myLocation.fulfilled]: (state, action) => {
			state.error = null;
			state.isLoading = false;
			state.currentLocation = action.payload;
		},
		[myLocation.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = "Sorry no data available, please try later";
		}
	}
});

export const { actions } = locationSlice;
export default locationSlice.reducer;