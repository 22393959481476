import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const sliceName = "allDetails";

export const fetchDetails = createAsyncThunk(
  `${sliceName}/fetchDetails`,
  async (detailsLink) => {
    const { data } = await Axios.get(`/detailsapi/${detailsLink}`);
    return data;
  }
);

export const detailsSlice = createSlice({
  name: sliceName,
  initialState: {
    details: [],
    isLoading: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchDetails.pending]: (state, action) => {
      state.error = null;
      state.isLoading = true;
    },
    [fetchDetails.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.details = action.payload;
    },
    [fetchDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = "Sorry no data available, please try later";
    },
  },
});
export const { actions } = detailsSlice;
export default detailsSlice.reducer;
