import React from 'react';
import { Link } from 'react-router-dom';
import data from './provinces.json';
import styles from "./provinces.module.css";

const Provinces = () => {

	const { provinces } = data;

	return (
		<div className={styles.container}>
			<h1 className={styles.heading}>Provinces</h1>
			{
				provinces.map((res, index) => (
					<div key={index}>
						<Link to={`/cities/${res.province.link}`}>
							<h2>{res.province.name}</h2>

						</Link>
					</div>
				)
				)}
		</div>
	);

}


export default Provinces
// if (navigator) {
    // navigator.geolocation.getCurrentPosition(async (pos) => {
    //   const lat = await pos.coords.latitude;
    //   const lon = await pos.coords.longitude;