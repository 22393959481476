import Details from "./details/Details";
import Movies from "./movies/Movies";
import Provinces from "./provinces/Provinces";
import Cities from "./cities/Cities";
import Theatres from "./theatres/Theatres";
import Faves from "./faves/Faves";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import styles from "./App.module.css";
import Location from "./location/Location";

function App() {
  return (
    <div className={styles.app}>
      <div className={styles.container}>
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={Provinces} />
            <Route path="/cities/:province" component={Cities} />
            <Route path="/theatres/:province/:city/:provincename/:cityname" component={Theatres} />
            <Route path="/faves" component={Faves} />
            <Route path="/movies/:theatrelink1/:theatrelink2" component={Movies} />
            <Route path="/details/:details1/:details2/:details3" component={Details} />
            <Route path="/details/:details1/:details2/:details3" component={Details} />
            <Route path="/location" component={Location} />
            <Route path="*" component={Provinces} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
