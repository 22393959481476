import React from 'react';
import styles from './spinner.module.css';

const Spinner = () => {
	return (
		<div className={styles.spinner}>
			<img src='/805.svg' alt="Spinning circle" />
		</div>
	)
}

export default Spinner
