import React from 'react';
import {  useSelector, useDispatch } from 'react-redux';
import {Link} from 'react-router-dom';
import {removeFavTheatre} from '../features/favsSlice';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './faves.module.css';


const Faves = () => {

	const dispatch = useDispatch();
	const {favTheatres} = useSelector((state) => state.faves);
	
	// If there are no favs, display a message
	if (favTheatres.length === 0) {
		return (
		<h2 className={styles.message}>You have no favourites saved</h2>
		);
	}

  return (
		<div className={styles.container}>
			<div className={styles.heading1}><h1>Favourite Theatres</h1></div>
		<div>
			{favTheatres && favTheatres.map((res, index ) => (
				<div key={index}>
				<Link to={`/movies/${res.link}`}>				
					<h2>{res.name}</h2>
				</Link>
					<div className={styles.deleteButton} onClick={() => dispatch(removeFavTheatre(res.name))}>Delete Favourite<DeleteIcon fontSize="small" className={styles.deleteIcon}/></div>
				</div>
			))}
		</div>	

		</div>
	
		);
} 
			


export default Faves;