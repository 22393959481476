import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const sliceName = "allCities";

export const fetchCities = createAsyncThunk(
	`${sliceName}/fetchCities`,
	async (province) => {
		const { data } = await Axios.get(`/citiesapi/${province}`);
		return data;
	}
);

export const citiesSlice = createSlice({
	name: sliceName,
	initialState: {
		cities: [],
		isLoading: false,
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchCities.pending]: (state, action) => {
			state.error = null;
			state.isLoading = true;
		},
		[fetchCities.fulfilled]: (state, action) => {
			state.error = null;
			state.isLoading = false;
			state.cities = action.payload;
		},
		[fetchCities.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = "Sorry no data available, please try later";
		}
	}
});

export const { actions } = citiesSlice;
export default citiesSlice.reducer;