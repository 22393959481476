import { configureStore } from "@reduxjs/toolkit";
import theatresReducer from "../features/theatresSlice";
import allMoviesReducer from "../features/moviesSlice";
import allDetailsReducer from "../features/detailsSlice";
import allCitiesReducer from "../features/citiesSlice";
import locationReducer from '../features/locationSlice';
import favReducer from "../features/favsSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";


const persistConfig = {
  key: "root",
  storage,
  whitelist: ["faves"],
  // blacklist: ["state_which_you_do_not_want_to_persist"]
};

const reducer = combineReducers({
  allTheatres: theatresReducer,
  allMoviesStore: allMoviesReducer,
  allDetails: allDetailsReducer,
  allCities: allCitiesReducer,
  faves: favReducer,
  allLocation: locationReducer
});


const persistedReducer = persistReducer(persistConfig, reducer);


const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);

export default store;
